
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import utils from '@/utils/index'
import useJwt from '@/auth/jwt/useJwt'
import Vue from "vue";
import eventBus from "./event-bus";
// import { xwsStorage } from "../utils";

// Use toast
const toast = useToast();

const axios = require("axios");

const https = require('https');

let activePendingRequestWithLoader = 0;

const urlParams = utils.getQueryParams();

var instance = axios.create({
  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: false
  // })
  baseURL: "",
  timeout: 30 * 1000
});

instance.interceptors.request.use(function (param) {
  if (param && param.data && param.data.isFormData) {
    var postData = new FormData();
    if (param.data) {
      var keys = Object.keys(param.data);
      for (var i = 0; i < keys.length; i++) {
        postData.append(keys[i], param.data[keys[i]]);
      }
    }
    param.data = postData;
  }
  return param;
});

/**
 * Runs loader stuffs before the request is sended.
 * 
 * @param {Boolean} hideLoadingSpinner indicates whether to hide the loader.
 */
 const requestLoaderPreProcessor = (hideLoadingSpinner = false) => {
  if(hideLoadingSpinner) return;
  
  if (activePendingRequestWithLoader <= 0) eventBus.$emit('REQUEST_PENDING_INVOKE_LOADER', true);
  activePendingRequestWithLoader += 1;
};

/**
 * Runs loader stuffs after the request is completed.
 * 
 * @param {Boolean} hasHideLoader indicates whether the request required to hide the loader..
 */
const requestLoaderPostProcessor = (hasHideLoader = false) => {
  if(hasHideLoader) return;

  const val = activePendingRequestWithLoader - 1;

  if (val <= 0) {
    eventBus.$emit('REQUEST_PENDING_INVOKE_LOADER', false);
  }

  activePendingRequestWithLoader -= 1;
};

var processResponse = function (response, path, data, extra) {
  requestLoaderPostProcessor(extra.hideLoadingSpinner);
  if (response && response.data && response.data.errorMsg) {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Error',
        icon: 'BellIcon',
        text: response.data.errorMsg,
        variant: 'outline-warning'
      }
    });
  }

  return response.data;
};

var processError = function (responseError, path) {
};

const getRequestConfig = function (params, extra = {}) {
  // hide loading
  if (extra.hideLoadingSpinner) {
    // handleLoading(false);
  } else {
    // handleLoading(true);
  }

  let env = process.env.NODE_ENV;

  const exitingToken = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName);
  const requestToken = "Bearer " + (exitingToken ? exitingToken.replaceAll('"', '') : '');
  const user = JSON.parse(localStorage.getItem('userData')) || {};

  // config
  return {
    baseURL: "",
    headers: {
      "X-Force-Object": "0",
      "X-Platform": "H5",
      "X-System": "",
      "X-Brand": "",
      "X-Model": "",
      "X-Language": "",
      "X-Network": "",
      "X-Lat": "",
      "X-Lon": "",
      "X-Platform-Id": "1qmchtzl2u7e",
      "Authorization": requestToken,
      // "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1YWY4NzU0ODllMzM0Zjg3YTJjMzIyNzc5ZjhhYjU4NCIsImZpcnN0TmFtZSI6IkFuc2VsIiwibGFzdE5hbWUiOiJBZG1pbiIsInVmSWQiOiJja2R0bjhseGJzbTZ5aCIsInJvbGUiOjEwMDAsIm9yZ0lkIjoiNmw5aG52YnNhYm5tOGMiLCJwaG9uZSI6IisyMzc2NzExMTExMTEiLCJpYXQiOjE2MzU0MzcwODksImV4cCI6MTYzNzUxMDY4OX0.yYNp-1kraA2Uu58dvs0TF1B35tPfxxxAfuDs4Fl4ibA",
      // "X-Token": requestToken || "",
      // "X-app-version": RELEASE_VERSION || "", // 暂未使用
      "X-Nonce": "",
      "X-App-ID": user.scopeId || "wx560bc72200913398",
      "X-App-Market": "",
      "X-Tz-Distinct-Id": utils.getDistinctId(),
    }, // `params` are the URL parameters to be sent with the request // `headers` are custom headers to be sent
    // Must be a plain object or a URLSearchParams object
    // params: {},
    data: {},
    timeout: 30 * 1000, // You may modify the headers object. // 默认30秒。可以按照具体业务修改timeout 值
    responseType: "json"
  }; // default
};

const unkownResult = {
  err: {
    ec: "0",
    em: "未知错误"
  },
  isUnkownError: true
};

export default {
  post: function (path, data, extra = { hideLoadingSpinner: false }) {
    requestLoaderPreProcessor(extra.hideLoadingSpinner);
    return instance.post(path, data, getRequestConfig({}, extra)).then(response => {
      let result = processResponse(response, path, data, extra);
      if (result && result.err) {
        throw result;
      } else if (result) {
        return result;
      } else {
        return {};
      }
    })
      .catch(err => {
        console.log("-----||||||||||||||err--api||||||||||------>>>--", err);
        requestLoaderPostProcessor(extra.hideLoadingSpinner);
        throw err;
      });
  },
  put: function (path, data, extra = { hideLoadingSpinner: false }) {
    requestLoaderPreProcessor(extra.hideLoadingSpinner);
    return instance.put(path, data, getRequestConfig({}, extra)).then(response => {
      let result = processResponse(response, path, data, extra);
      if (result && result.err) {
        throw result;
      } else if (result) {
        return result;
      } else {
        return {};
      }
    })
      .catch(err => {
        console.log("-----||||||||||||||err--api||||||||||------>>>--", err);
        requestLoaderPostProcessor(extra.hideLoadingSpinner);
        throw err;
      });
  },
  get: function (path, extra = { hideLoadingSpinner: false }) {
    requestLoaderPreProcessor(extra.hideLoadingSpinner);
    return instance
      .get(path, getRequestConfig({}, extra))
      .then(response => {
        let result = processResponse(response, path, null, extra);
        if (result.err) {
          throw result;
        } else {
          return result;
        }
      })
      .catch(() => {
        processError(unkownResult, path);
        requestLoaderPostProcessor(extra.hideLoadingSpinner);
        throw unkownResult;
      });
  }
};
