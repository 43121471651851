// const UPLOAD_ROOT = API_ROOT;
// const AUTH_API_ROOT = API_ROOT;
let env = process.env.NODE_ENV;

const PROD = "https://api.tranzak.me";

const STAG = "https://staging-api.tranzak.me";

// const DEV = "http://localhost:3001";
const DEV = "http://localhost:6110";

let baseUrl = PROD;

// if (env === "dev") {
//   baseUrl = DEV;
// } else if (env === "staging") {
  baseUrl = STAG;
// }

export default {
  main: baseUrl,
  envName: env
};
