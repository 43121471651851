<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />

    <!-- Global modal -->
    <b-modal
      v-show="showLoader"
      size="md"
      id="tz-app-loader"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="d-flex flex-row justify-content-center align-items-center">
        <span class="text-primary">
          <loader-icon size="3x" class="spinner"></loader-icon>
        </span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { ref, watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import io from "socket.io-client";

import chatModuleStore from "@/views/apps/chat/chat.module.store";
import utils from "@/utils";
import constants from "@/services/constants";
import eventBus from "@/services/event-bus";
import { LoaderIcon } from "vue-feather-icons";


const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
    LoaderIcon
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  created() {
    this.initSocketForNotifications();
    this.initSocketForChat();

    eventBus.$on("REQUEST_PENDING_INVOKE_LOADER", this.toggleLoader);
  },
  methods: {
    initSocketForNotifications() {
      setTimeout(() => {
        try {
          const socket = io(`https://tzns.tranzak.me`, {
            transports: ["websocket", "polling"],
          });
          socket.connect();
          const user = utils.localStorage.get("userData");

          socket.on("connect", async () => {
            this.socketNotifications = socket;
            console.log('NOTIF SOCKET', this.socketNotifications)

            if (!this.socketNotifications) return;

            this.socketNotifications.emit("identify", {
              socketId: this.socketNotifications.id,
              scopeId: user?.scopeId,
            });

            this.socketNotifications.emit("subscribe", {
              scopeId: user?.scopeId,
            });

            this.socketNotifications.on("new_notification", data => {
              console.log("new_notification", data);

              eventBus.$emit(constants.events.NEW_NOTIFICATION, data);
            });
          });

          socket.on("reconnect", () => {
            socket.emit("subscribe", {
              scopeId: user?.scopeId,
            });
          });

          socket.on("connect_error", data => {
            console.log("data====||||||====", data);
            // revert to classic upgrade
            socket.io.opts.transports = ["polling", "websocket"];
          });
        } catch (error) {
          console.log(error);
        }        
      }, 5000);
    },
    initSocketForChat() {
      setTimeout(() => {
        try {
          const socket = io(`https://im.tranzak.me`, {
            transports: ["websocket", "polling"],
          });
          socket.connect();
          const user = utils.localStorage.get("userData");

          const loadConversationsAndSubsribe = async () => {
            const conversations =
              await chatModuleStore.actions.filterConversations(
                {},
                { hideLoadingSpinner: true }
              );

            conversations.forEach(c => {
              const otherUser = c.users.find(x => x.scopeId != user?.scopeId);

              this.socketChat.emit(
                "subscribe",
                {
                  room: c._id,
                  otherUserScopeId: otherUser?.scopeId,
                  scopeId: user?.scopeId,
                },
                () => console.log("SUBSCRIBED")
              );
            });
          };

          socket.on("connect", async () => {
            this.socketChat = socket;
            console.log('CHAT SOCKET', this.socketChat);

            if (!this.socketChat) return;

            this.socketChat.emit("identify", {
              socketId: this.socketChat.id,
              scopeId: user?.scopeId,
            });

            await loadConversationsAndSubsribe();

            this.socketChat.on("new_message", data => {
              console.log("new_message", data);
              eventBus.$emit(constants.events.NEW_MESSAGE, data);
            });

            this.socketChat.on("delete_message", (messageId, chatroomId) => {
              console.log("delete_message", messageId, chatroomId);
              eventBus.$emit(constants.events.DELETE_MESSAGE, messageId, chatroomId);
            });
          });

          socket.on("reconnect", loadConversationsAndSubsribe);
        } catch (err) {
          console.log(err);
        }
      }, 10000);
    },
    toggleLoader(show) {
      if (show) {
        /**
         * Prevent displaying multiple modals.
         */
        if (this.isLoaderVisible) return;
        /**
         * Display loader.
         */
        this.$bvModal.show("tz-app-loader");
        /**
         * Toggle view params;
         */
        this.isLoaderVisible = true;
        this.showLoader = true;
      } else {
        /**
         * Stop execution if loader is visible.
         */
        if (!this.isLoaderVisible) return;
        /**
         * Hide loader.
         */
        this.$bvModal.hide("tz-app-loader");
        /**
         * Toggle view params;
         */
        this.isLoaderVisible = false;
        this.showLoader = false;
      }
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    const showLoader= ref(false);
    const isLoaderVisible = ref(false);

    const socketChat = ref(null);
    const socketNotifications = ref(null);

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
      socketChat,
      socketNotifications,
      showLoader,
      isLoaderVisible,
    }
  },
}
</script>
<style lang="scss">
#tz-app-loader * {
  background: transparent;
  box-shadow: none;
}
#tz-app-loader + .modal-backdrop {
  background: rgba(0, 0, 0, 0.3) !important;
}
</style>
