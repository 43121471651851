import dayjs from 'dayjs'

export default {
  formatCurrency(value, currency, language = 'en-US') {
    //currency eg USD
    //language eg fr-FR, en-US
    return new Intl.NumberFormat(language, { style: 'currency', currency: currency }).formatToParts(value).map(
      p =>{ 
        if(p.type != 'literal' && p.type != 'currency') {
          return p.value;
        }
        return '';
      }
    ).join('');
  },
  isValidCmNumber(phone) {
      return /^6[256789]\d{7}$/.test(phone);
  },
  isMtn(phone) {
      return /^67/.test(phone) || /^65[0-4]/.test(phone);
  },
  isOrange(phone) {
      return /^69/.test(phone) || /^65[5-9]/.test(phone);
  },
  isCamtel(phone) {
      return /^2/.test(phone) || /^3/.test(phone);
  },
  isNextel(phone) {
      return /^66/.test(phone);
  },
  formatNumber: function (n) {
    const str = n.toString();
    return str[1] ? str : `0${str}`;
  },
  getQueryParams: () => {
    const params = {}

    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      if (value === 'undefined') {
        value = ''
      }

      params[key] = decodeURIComponent(value)
    })

    // noCache
    if (params.noCache) {
      // clear cache
      this.xwsStorage.clearAll()
    }

    if (params.resetAuth) {
      // clear resetAuth
      this.xwsStorage.clear('token')
      this.xwsStorage.clear('xwsUserInfo')
    }

    return params
  },
  loadScript (path, callback, scriptId) {
    scriptId = scriptId || `external-${Date.now()}`
    setTimeout(() => {
      ((d, s, id) => {
        let js
        const fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {
          return
        }
        js = d.createElement(s)
        js.id = id
        js.onload = function () {
          if (typeof callback === 'function') callback()
        }
        js.src = path
        js.type = 'text/javascript'
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', scriptId)
    }, 300)
  },
  localUserInfo: {
    get: () => {
      const result = localStorage.getItem('xwsUserInfo')

      return result ? JSON.parse(result) : {}
    },
    set: user => {
      localStorage.setItem('xwsUserInfo', JSON.stringify(user))
    },
    clearToken: () => {
      // localStorage.removeItem(TOKEN_STORAGE_KEY);
    }
  },
  localStorage: {
    get(name) {
      const data = localStorage.getItem(name);

      return data === undefined ? null : JSON.parse(data);
    },
    set(name, data) {
      localStorage.setItem(name, JSON.stringify(data));
    },
    clear(name) {
      localStorage.removeItem(name);
    }
  },
  getUUID () {
    const id = Math.random()
      .toString(36)
      .substring(5)
      + Date.now()
      + Math.floor(1000000 * Math.random()).toString()

    return id
  },
  getDistinctId () {
    let xwsGlobalDistinctId = localStorage.getItem('tzDistinctId')

    if (!xwsGlobalDistinctId) {
      xwsGlobalDistinctId = this.getUUID()
      localStorage.setItem('tzDistinctId', xwsGlobalDistinctId)
    }

    return xwsGlobalDistinctId
  },
  numberWithUnit: (num = 0, decimal = 1) => {
    if (num >= 10000) {
      return `${(num / 10000).toFixed(decimal)}W`
    }
    return num.toFixed(0)
  },
  formatDate: value => dayjs(String(value)).format('DD.MM.YYYY hh:mm'),
  coverNum: num => {
    if (isNaN(num) || parseInt(num) < 0) {
      return '00'
    }
    if (parseInt(num) < 10) {
      return `0${num}`
    }
    return num
  },
  completeTable: function(data = []) {
    if(data.length >= 10) {
      return data;
    }
    const boundary = 10 - data.length;
    for(let i = 0; i < boundary; i++) {
      data.push({});
    }
    return data;
  },
  messageTypes: Object.freeze({
    TEXT: 1,
    IMAGE: 2,
    VIDEO: 3,
    AUDIO: 4,
    EMOJI: 5,
    CASH: 10
  }),
  secondsToDisplayTime(seconds) {
    const date = new Date(seconds * 1000).toISOString();

      if(seconds >= 3600) {
        return date.substr(11, 8);
      }

      return date.substr(14, 5);
  },
  showErrorToast: (vm, msg, title = "Error") => {
    try {
      vm.$bvToast.toast(msg, {
        title: title,
        solid: false,
        toaster: 'b-toaster-top-center',
        variant: 'primary',
      });
  
    } catch (e) { console.log(e); }
  },
  showInfoToast: (vm, msg, title = "Info") => {
    
    try {
      vm.$bvToast.toast(msg, {
        title,
        solid: false,
        toaster: 'b-toaster-top-center',
        variant: 'info',
      });    
    } catch (e) { console.log(e); }
  }
}
